import { Controller } from "stimulus";

export default class extends Controller {
  changeRoles(event) {
    let institutionId = event.currentTarget.value;
    let roles = document.querySelector("#user_role");
    let gcsd = document.querySelectorAll('[value="GCSD"]');

    if (institutionId != "16" && gcsd.length) {
      for (let i = 0; i < roles.length; i++) {
        if (roles.options[i].value == "GCSD") roles.remove(i);
      }
    }

    if (institutionId == "16" && !gcsd.length) {
      let opt = document.createElement("option");
      opt.value = "GCSD";
      opt.innerHTML = "Subgerencia de Gestión de Denuncias (CGR - OCI)";
      roles.appendChild(opt);
    }

    for (let i = 0; i < roles.length; i++) {
      if (roles.options[i].value == "SIP")
        if(institutionId == "63")  {//para PCM (id=63) si aparece SIP
          roles.options[i].style.display = "";
        }
        else {
          roles.options[i].style.display = "none";
          roles.value = "";
        }
    }
  }
}
